<template>
  <div>
   <div v-if="!isPremium() && isAdmin()" class="e-banner warning e-rounding m-2 shadow-sm d-flex align-items-center">
     <i class="fas fa-info-circle pr-3"></i>
     <p class="mb-0">
       {{ $t('premium.info') }} <a href="/#/premium">{{ $t('premium.info.link.title') }}</a>
     </p>
   </div>
    <div style="height: 2rem"/>
  </div>
</template>

<script>
    export default {
      methods: {
        isPremium: function() {
          if(localStorage.premium === 'false') {
            return false;
          }
          //we do not know or we have a premium
          return true;
        },
        isAdmin: function () {
          if(localStorage.role === 'admin') {
            return true;
          }
          return false;
        },
      }
    }
</script>

<style>
.e-banner {
  padding: .7rem 1rem;
}

.e-banner.warning {
  background-color: #e57e17;
}
</style>
